<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-card-text>
        <v-text-field
          :label="$t('roles.fields.name')"
          v-model="name"
          prepend-icon="mdi-rename-box"
          :rules="required"
        ></v-text-field>
      </v-card-text>

      <!-- Create the custom permission picker -->
      <v-card-text>
        <permission-picker v-model="permissions" />
      </v-card-text>

      <v-card-text>
        <v-btn @click="submit">Submit</v-btn>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import PermissionPicker from "./PermissionPicker.vue";

export default {
  name: "RoleCreate",

  components: {
    PermissionPicker,
  },

  data() {
    return {
      valid: false,
      name: "",
      permissions: [],
      required: [(v) => !!v || this.$t("dashboard.nameReq")],
    };
  },

  computed: {},

  methods: {
    ...mapActions("roles", ["createRole"]),

    async submit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      var payload = {
        name: this.name,
        permissionGuids: this.permissions,
      };

      await this.createRole(payload);

      // Create the role
    },
  },

  created() {},
};
</script>